import React, {useEffect, useState} from "react"
import {changeOverlayContent} from "events/OverlayEvents";
import "./RSEPopUp.scss";

const RSEPopUp = (props) => {
    const {setPopupVisible} = props;

    const close = (e) => {
        e.preventDefault();
        localStorage.setItem("rse2025Hidden", true);
        changeOverlayContent(null);
        setPopupVisible(false);
    };

    return <div id="rse-popup">
        <div id="rse-popup-close" onClick={(e) => close(e)}>
            <i className="fal fa-times close-icon"></i>
        </div>

        <div id="rse-popup-header"></div>

        <div id="rse-popup-body">
            <div id="rse-popup-body-title">Devenez acteur de <br/><strong>la stratégie RSE de Synchrone !</strong></div>
            <p><strong>Dans le cadre de notre démarche Développement Durable, nous souhaitons intégrer des salariés
                d’horizons
                divers à nos réflexions et initiatives en matière d’environnement, de responsabilité sociétale et
                d'éthique.</strong></p>
            <p>Vous êtes sensible aux sujets tels que l'écologie, la QVCT, le numérique responsable,
                l'économie solidaire (…) ?</p>
            <p> Rejoignez notre <strong>Comité RSE</strong> pour construire ensemble un avenir plus durable !</p>
            <div id="popup-signing">
                <img id="signing-picture" src="/img/rse/signing.png"/>
                <div id="signing-text">
                    <div id="signing-name">
                        Aurélie VANDEN BIL,
                    </div>
                    <div id="signing-job">Directrice de la Performance & de la Transformation</div>
                </div>

            </div>
        </div>

        <div id="rse-popup-footer">
            <div className="footer-content">Faites-vous connaître, faites connaître vos idées</div>
            <a href="mailto:vandenbil@synchrone.fr?subject=Je%20contribue%20à%20la%20stratégie%20RSE%20Synchrone&body=Je%20précise%20mon%20prénom%20et%20mon%20nom.%20Je%20peux%20préciser%20les%20domaines%20d'intervention%20qui%20m'intéressent"
               className="footer-btn">JE CONTRIBUE</a>
        </div>

    </div>
}

export default RSEPopUp