import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import "moment/locale/fr";
import * as Yup from "yup";
import {Formik, yupToFormErrors} from "formik";

import Lightbox from "components/common/Lightbox";
import {changeOverlayContent} from "events/OverlayEvents";

import "./ExtraActivitiesPopUp.scss"
import "./ConfirmSendMonth.scss"
import ExtraActivitiesForm from "./ExtraActivitiesForm";
import {useDispatch, useSelector} from "react-redux";
import * as TimesheetActions from "actions/Timesheet";
import ExtraActivityConfig from "../../../entities/ExtraActivityConfig";
import {setLoading} from "../../../actions/Common";
import LightboxHeader from "../../common/LightboxHeader";
import ExtraDayOverlay from "../../mobile/TimesheetMobile/ExtraDayOverlay";

Yup.setLocale({
    date: {
        default: "Format de date invalide",
    },
});

const ExtraActivitiesPopUp = (props) => {
    const {day, monthEntity, modality, type} = props;
    const [config, setConfig] = useState()
    const [missingConfig, setMissingConfig] = useState()
    const missingWeek = useSelector((state) => state.timesheet.entities.missingExtraActivityWeek)
    // const displayExtraOverlay = useSelector(state => state.timesheet.displayExtraOverlay)
    const resetBtn = useRef(null)

    const dispatch = useDispatch();

    // const canDisplayExtraOverlay = () => {
    //     return displayExtraOverlay
    // }

    useEffect(() => {
        dispatch(TimesheetActions.fetchExtraActivityForWeek(monthEntity.year, monthEntity.month, moment.parseZone(day.dateAt).isoWeek()))

        if ((moment.parseZone(day.dateAt).month() + 1 > monthEntity.month) || (monthEntity.month.toString() === "12" && moment.parseZone(day.dateAt).month() === 0)) {
            dispatch(TimesheetActions.fetchMissingExtraActivityForWeek(monthEntity.month.toString() !== '12' ? monthEntity.year : (monthEntity.year + 1), monthEntity.month.toString() !== '12' ? (monthEntity.month + 1) : '1', moment.parseZone(day.dateAt).isoWeek()))

            Promise.resolve(
                dispatch(TimesheetActions.requestExtraActivitiesConfig(monthEntity.month.toString() !== '12' ? monthEntity.year : (monthEntity.year + 1), monthEntity.month.toString() !== '12' ? (monthEntity.month + 1) : '1', moment.parseZone(day.dateAt).isoWeek()))
            ).then((response) => {
                let newConfig = new ExtraActivityConfig(response.response.entities.extraActivityConfig.config);
                setMissingConfig(newConfig)
            })
        } else if ((moment.parseZone(day.dateAt).startOf('week').month() + 1 < monthEntity.month) || (monthEntity.month.toString() === "1" && moment.parseZone(day.dateAt).month() === 11)) {
            dispatch(TimesheetActions.fetchMissingExtraActivityForWeek(monthEntity.year, monthEntity.month.toString() !== '1' ? (monthEntity.month - 1) : '12', moment.parseZone(day.dateAt).isoWeek()))

            Promise.resolve(
                dispatch(TimesheetActions.requestExtraActivitiesConfig(monthEntity.year, monthEntity.month.toString() !== '1' ? (monthEntity.month - 1) : '12', moment.parseZone(day.dateAt).isoWeek()))
            ).then((response) => {
                let newConfig = new ExtraActivityConfig(response.response.entities.extraActivityConfig.config);
                setMissingConfig(newConfig)
            })
        }
    }, [day])
    const extraActivityWeek = useSelector((state) => {
        return state.timesheet.entities.extraActivityWeek;
    });

    const sanitizeForm = (values, forValidation) => {
        let copy = {...values}
        const complementaries = []
        copy.complementaries.map((day, index) => {
            let dayCopy;
            if (forValidation) {
                dayCopy = {date: day.date, hours: [], mission: day.mission}
            } else {
                dayCopy = {date: day.date, hours: []}
            }

            day.hours.map((hour) => {
                if (!(hour.start === '' && hour.end === '')) {
                    let hourCopy;
                    if (forValidation) {
                        hourCopy = hour;
                    } else {
                        hourCopy = {start: hour.start, end: hour.end, code: hour.code}
                    }
                    dayCopy.hours.push(hourCopy)
                }
            })

            if (dayCopy.hours.length > 0) {
                complementaries.push(dayCopy)
            }
        })

        const constraints = []
        copy.constraints.map((day, index) => {
            let dayCopy = {date: day.date, hours: []}
            day.hours.map((hour) => {
                if (!(hour.start === '' && hour.end === '')) {
                    let hourCopy;
                    if (forValidation) {
                        hourCopy = hour;
                    } else {
                        hourCopy = {start: hour.start, end: hour.end, code: hour.code}
                    }
                    dayCopy.hours.push(hourCopy)
                }
            })

            if (dayCopy.hours.length > 0) {
                constraints.push(dayCopy)
            }
        })

        return {
            comment: copy.comment,
            complementaries: complementaries,
            constraints: constraints,
        }
    }

    function noOverlap(msg) {
        return this.test({
            name: "noOverlap",
            exclusive: false,
            message: msg,
            test: function (value) {
                let isValid = true;
                const extraActivities = {...this.options.context.sanitizedForm}
                const days = [...extraActivities.complementaries, ...extraActivities.constraints];
                const hours = []

                days.map((day, key) => {
                    let date = day.date;
                    if (!hours[date]) {
                        hours[date] = []
                    }

                    day.hours.map((hour, hourKey) => {
                        let end = (hour.end === "00:00" ? "24:00" : hour.end)
                        hours[date].push({
                            "start": hour.start,
                            "end": end
                        })
                    })
                })

                Object.values(hours).map((dayHours, date) => {
                    for (let i = 0; i < (dayHours.length - 1); i++) {
                        for (let j = i + 1; j < dayHours.length; j++) {
                            let splitStartA = dayHours[i].start.split(':');
                            let splitEndA = dayHours[i].end.split(':');
                            let splitStartB = dayHours[j].start.split(':');
                            let splitEndB = dayHours[j].end.split(':');

                            let startA = (parseInt(splitStartA[0]) * 60) + parseInt(splitStartA[1])
                            let endA = (parseInt(splitEndA[0]) * 60) + parseInt(splitEndA[1])
                            let startB = (parseInt(splitStartB[0]) * 60) + parseInt(splitStartB[1])
                            let endB = (parseInt(splitEndB[0]) * 60) + parseInt(splitEndB[1])

                            if ((startA >= startB && startA < endB) || (startB >= startA && startB < endA) || (startA <= startB && endA >= endB) || (startB <= startA && endB >= endA)) {
                                isValid = false;
                                return;
                            }
                        }
                    }
                })

                return isValid;
            }
        })
    }

    function after(ref, msg) {
        return this.test({
            name: "after",
            exclusive: false,
            message: msg || "${path} must be after ${reference}",
            params: {
                reference: ref.path,
            },
            test: function (value) {
                if (value === "00:00" && this.resolve(ref) > "00:00") {
                    return true;
                }
                return value > this.resolve(ref);
            },
        });
    }

    Yup.addMethod(Yup.array, "noOverlap", noOverlap);
    Yup.addMethod(Yup.string, "after", after);

    const schema = Yup.object().shape({
        complementaries: Yup.array().of(
            Yup.object().shape({
                date: Yup.string(),
                mission: Yup.string().when(
                    '$config', (config, schema) => {
                        if (config.canDisplayEventCodesForComplementaries()) {
                            return schema
                                .required("Vous devez spécifier la mission");
                        }
                    }
                ).nullable(),
                hours: Yup.array().of(
                    Yup.object().shape({
                        start: Yup.string()
                            .required(
                                "Vous devez fournir un horaire de début"
                            )
                            .matches(
                                /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
                                "Le format de l'heure est incorrect"
                            ),
                        end: Yup.string()
                            .required(
                                "Vous devez fournir un horaire de fin"
                            )
                            .after(
                                Yup.ref("start"),
                                "L'horaire de fin doit être supérieur à l'horaire de début"
                            )
                            .matches(
                                /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
                                "Le format de l'heure est incorrect"
                            ),
                        code: Yup.string().when(
                            ['$config', 'start', 'end'], (config, start, end, schema) => {
                                if (config.canDisplayOvertimeCodesForComplementaries()) {
                                    if (start !== "" && end !== "") {
                                        return schema
                                            .required("Vous devez spécifier le code intervention");
                                    }
                                }
                            }
                        ).nullable()
                    })
                )
            })
        ).noOverlap("2 plages horaires ne peuvent pas se chevaucher"),
        constraints: Yup.array().of(
            Yup.object().shape({
                date: Yup.string(),
                hours: Yup.array().of(
                    Yup.object().shape({
                        start: Yup.string()
                            .required(
                                "Vous devez fournir un horaire de début"
                            )
                            .matches(
                                /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
                                "Le format de l'heure est incorrect"
                            ),
                        end: Yup.string()
                            .required(
                                "Vous devez fournir un horaire de fin"
                            )
                            .after(
                                Yup.ref("start"),
                                "L'horaire de fin doit être supérieur à l'horaire de début"
                            )
                            .matches(
                                /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
                                "Le format de l'heure est incorrect"
                            ),
                        code: Yup.string().when(
                            ['$config', 'start', 'end'], (config, start, end, schema) => {
                                if (config.canDisplayOvertimeCodesForConstraints()) {
                                    if (start !== "" && end !== "") {
                                        return schema
                                            .required("Vous devez spécifier le code intervention");
                                    }
                                }
                            }
                        ).nullable()
                    })
                )
            })
        )
    })

    useEffect(() => {
        // Récupération de la config pour build le form
        Promise.resolve(
            dispatch(TimesheetActions.requestExtraActivitiesConfig(monthEntity.year, monthEntity.month, moment.parseZone(day.dateAt).isoWeek()))
        ).then((response) => {
            setConfig(new ExtraActivityConfig(response.response.entities.extraActivityConfig.config))
        });
    }, [day])

    const close = () => {
        dispatch(TimesheetActions.clearExtraActivityWeek());
        changeOverlayContent(null);
    };

    const resetForm = () => {
        resetBtn.current.click()
    }

    if (!config) {
        return <div>Loading</div>
    }

    return config && <Lightbox className="lb-w1200">
        <div className="lb-extra-activities">
            <LightboxHeader>
                <div className="lb-extra-activities-header">
                    <div className="extra-activities-header-title">
                        Déclarer des activités complémentaires
                    </div>
                    <i className="close-lb fal fa-times" onClick={close}/>
                </div>
            </LightboxHeader>
            <div className={"lb-extra-activities-content"}>
                <div className={"bold"}>Semaine
                    du {config.firstDay().format("DD MMMM YYYY")} au {config.lastDay().format("DD MMMM YYYY")} {modality !== "Inconnue" ? (" - Temps de travail contractuel " + modality) : ""}</div>
                <br/>
                <div className="warning-message bold">
                    <i className="extra-activities-warning far fa-exclamation-square "></i>{" "}
                    Saisie d'une activité complémentaire ce mois-ci
                </div>
                <br/>
                <div className={"extra-activities-message"}>
                    Si vous avez réalisé
                    des {type !== "contractor" ? "activités complémentaires, avec l’accord préalable de votre manager" : "prestations complémentaires avec un accord préalable"},<br/>
                    nous vous invitions à indiquer ci-dessous les plages horaires de ces activités.
                </div>
                <div className="delete-extra-activities-btn" onClick={resetForm}>
                    <i className="far fa-trash-alt"></i>{" "}
                    Tout supprimer
                </div>
            </div>

            <div className={"extra-activities-form-container"}>
                {/*<div className={"extra-activities-form-container"+(canDisplayExtraOverlay() ? " with-overlay" : "")}>*/}
                <ExtraDayOverlay/>
                <div
                    className={"extra-activities-form-header" + (config.canDisplayEventCodesForComplementaries() ? " extra-activities-form-header-sm" : " extra-activities-form-header-lm")}>
                    {config.canDisplayComplementaries() && <div className="header-container hours-not-worked-header">
                        {config.canDisplayEventCodesForComplementaries() &&
                            <div className="header-mission">Mission</div>}
                        <div className="header-left">
                            Horaires activité complémentaire par jour :
                        </div>
                        {config.canDisplayOvertimeCodesForComplementaries() && <div className="header-center">
                            Code
                        </div>}

                        <div className="header-total">Total :</div>
                    </div>}
                    {config.canDisplayConstraints() && (<div
                        className={`header-container intervention-header ${config.canDisplayComplementaries() ? "" : "no-margin-left"}`}>
                        <div
                            className={"header-left" + (!config.canDisplayOvertimeCodesForConstraints() ? "" : " no-margin-left")}>
                            Horaires intervention en astreinte :
                        </div>
                        {config.canDisplayOvertimeCodesForConstraints() && <div className="header-center">
                            Code intervention en astreinte *
                        </div>}
                        <div className="header-total">Total :</div>
                    </div>)}
                </div>

                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={config.buildForm(extraActivityWeek)}
                    validate={(values, props) => {
                        let sanitizedForm = sanitizeForm(values, true)
                        return schema.validate(sanitizedForm, {
                            abortEarly: false,
                            context: {config, sanitizedForm}
                        }).then(() => true
                        ).catch((err) => {
                            console.log(err);
                            dispatch(TimesheetActions.notifyError(err.errors[0]));
                            return yupToFormErrors(err);
                        })
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        console.log(extraActivityWeek)
                        let sanitizedForm = sanitizeForm(values, false)
                        let httpVerb;
                        if (extraActivityWeek.id) {
                            httpVerb = "PATCH"
                        } else {
                            httpVerb = "POST"
                        }
                        dispatch(setLoading(true));
                        dispatch(
                            TimesheetActions.sendExtraActivityForWeek(
                                monthEntity.year,
                                monthEntity.month,
                                moment.parseZone(day.dateAt).isoWeek(),
                                JSON.stringify(sanitizedForm),
                                httpVerb
                            )
                        ).then(() => {
                            changeOverlayContent(null);
                            dispatch(TimesheetActions.clearExtraActivityWeek());
                            dispatch(setLoading(false));

                            Promise.all([
                                dispatch(
                                    TimesheetActions.fetchEntireMonth(monthEntity.month, monthEntity.year)
                                ),
                                dispatch(
                                    TimesheetActions.notifySuccess(
                                        "Votre déclaration a été envoyée"
                                    )
                                ),
                            ]);
                        });
                    }}
                >
                    {({errors, isSubmitting}) => {
                        return <ExtraActivitiesForm config={config} resetBtn={resetBtn} missingConfig={missingConfig}/>
                    }}
                </Formik>
            </div>
        </div>
    </Lightbox>;
}

export default ExtraActivitiesPopUp;
